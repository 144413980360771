import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

const sentryIntegrations = [
  Sentry.browserTracingIntegration(),
  Sentry.replayIntegration(),
];
(environment.production || window.location.hostname.startsWith('staging.')) &&
  sentryIntegrations.push(
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: 'system',
    })
  );

function getEnvFromHostname(hostname: string) {
  if (['localhost', '127.0.0.1', '.devtunnels.ms'].includes(hostname))
    return 'development';

  if (hostname.startsWith('staging.')) return 'staging';

  return 'production';
}

const sentryEnv = getEnvFromHostname(window.location.hostname);
if (sentryEnv !== 'development') {
  Sentry.init({
    // dsn with dynamic hostname
    dsn:
      'https://5f631e3d71b5d214d86baf1f7e11c6cf@sentry.' +
      window.location.hostname +
      '/1',
    integrations: sentryIntegrations,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      '127.0.0.1',
      /^https:\/\/(staging\.)?heizung\.hilaren\.de/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: sentryEnv,
  });
}

globalThis.appVersion = '2.0.6';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
